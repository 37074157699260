import { APIURL } from "../env";
import { PostDecryptEmailDTOS } from "../models/post/decrypt.email.dtos";
import { PostEncryptedEmailDTOS } from "../models/post/encrypted.email.dtos";
import { DescryptEmailDTOS } from "../models/responses/descrypt.email.dtos";
import { EncryptedEmailDTOS } from "../models/responses/encrypted.email.dtos";
import { IResult, Result } from "../result";

export class EmailService {
    async encryptEmail(params: PostEncryptedEmailDTOS): Promise<IResult<EncryptedEmailDTOS, Error>> {
        try {
            const response = await fetch(`${APIURL}/encrypt-email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
            });
            
            if (!response.ok) {
                return Result.failure(new Error(`Failed to fetch: ${response.statusText}`));
            }

            const data: EncryptedEmailDTOS = await response.json();
            return Result.success(data);
        } catch (error) {
            return Result.failure(error as Error);
        }
    }

    async decryptEmail(params: PostDecryptEmailDTOS): Promise<IResult<DescryptEmailDTOS, Error>> {
        try {
            const response = await fetch(`${APIURL}/decrypt-email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
            });
            
            if (!response.ok) {
                return Result.failure(new Error(`Failed to fetch: ${response.statusText}`));
            }

            const data: DescryptEmailDTOS = await response.json();
            return Result.success(data);
        } catch (error) {
            return Result.failure(error as Error);
        }
    }

    async acceptTerms(params: PostDecryptEmailDTOS): Promise<IResult<DescryptEmailDTOS, Error>> {
        try {
            const response = await fetch(`${APIURL}/accept-terms`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
            });
            
            if (!response.ok) {
                return Result.failure(new Error(`Failed to fetch: ${response.statusText}`));
            }

            const data: DescryptEmailDTOS = await response.json();
            return Result.success(data);
        } catch (error) {
            return Result.failure(error as Error);
        }
    }

    async checkEmail(params: PostDecryptEmailDTOS): Promise<IResult<{ exists: boolean }, Error>> {
        try {
            const response = await fetch(`${APIURL}/check-email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
            });
    
            if (!response.ok) {
                return Result.failure(new Error(`Failed to fetch: ${response.statusText}`));
            }
    
            const data: { exists: boolean } = await response.json();
            return Result.success(data);
        } catch (error) {
            return Result.failure(error as Error);
        }
    }
    
}
